<template>
  <!-- begin:: Footer -->
  <div
    class="footer bg-white py-4 d-flex flex-lg-column poppins position-fixed bottom-0 w-100"
    id="kt_footer"
  >
    <div
      class="d-flex align-content-center"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <a
        href="https://iqfulfillment.com"
        target="_blank"
        class="text-dark-75 poppins d-flex align-items-center"
      >
        <!-- <span> IQ Fulfillment</span> -->
        <img src="/img/IQ-FULFILLMENT-LOGO-PURPLE.png" class="w-120px mr-2" />
        <span class="mx-1 font-size-lg font-weight-bolder">Powered by: </span>
        <img width="68" src="/img/IQ-Hybrid-logo.png" class="ml-2" />
      </a>
      <span class="mx-1 mt-1">&nbsp;&copy;&nbsp;</span>
      <span class="font-weight-bold mx-0 mt-1">
        {{ new Date().getFullYear() }}
      </span>
    </div>
  </div>
  <!-- end:: Footer -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTFooter",
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if footer container is fluid
     */
    widthFluid() {
      return this.layoutConfig("footer.width") === "fluid";
    },
  },
};
</script>
