<template>
  <ul class="menu-nav pt-10 mt-10" v-if="isDataReady">
    <div v-for="menu in menus" :key="menu.title">
      <navi-compact v-if="menu.type === 'default'" :item="menu"></navi-compact>
      <navi-drop-down v-else :item="menu"></navi-drop-down>
    </div>
  </ul>
</template>

<script>
import store from "@/core/services/store";
import NaviCompact from "../../../own/components/menu/NaviCompact.vue";
import NaviDropDown from "../../../own/components/menu/NaviDropDown.vue";
export default {
  components: { NaviDropDown, NaviCompact },
  name: "KTMenu",
  computed: {
    menus: function () {
      const menus = this.$store.getters.currentUser.menu.map((item) => {
        if (item.type === "default") return item;
        else {
          const res = item.entities.filter(
            (subMenuItem) => subMenuItem.items.length
          );
          if (res.length) return { ...item, entities: [...res] };
        }
      });

      return menus.filter((item) => item !== undefined);
    },
    isDataReady: function () {
      try {
        return !!store.getters.currentUser.permissions;
      } catch (error) {
        return false;
      }
    },
  },
  methods: {
    hasPermissionToMenu(val) {
      let v = false;
      try {
        val.forEach((element) => {
          if (store.getters.currentUser.permissions.includes(element)) {
            v = true;
          }
        });
      } catch (e) {
        // console.log(e);
      }
      return v;
    },
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
  },
};
</script>
